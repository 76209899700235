.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
}
.switch-delete {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: unset;
    margin-left: 10px;
    width: 110px;
}

.switch-delete .MuiSwitch-root{
    margin: 10px 0;
}

.switch-delete .MuiTypography-root {
    font-size: 13px;
    font-weight: 700;
    font-family: 'Oxygen', sans-serif;
}
