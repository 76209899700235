.react-calendar {
    background: none !important;
    border: none !important;
    width: 230px !important;
}


.react-calendar__navigation__label {
    border-radius: 0.2rem;
    font-family: "Work Sans", "Roboto", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 1.067rem;
    font-weight: 600;
    letter-spacing: -0.03em;
}

.react-calendar__navigation__label:disabled {
    color: #1b2734;
}

.react-calendar__navigation__arrow{
    font-size: 250%;
}


.react-calendar__month-view__weekdays__weekday {
    color: #98a1ab;
    cursor: inherit;
    display: inline-flex;
    font-size: 100%;
    justify-content: center;
    line-height: 32px;
    white-space: nowrap;
    text-transform: capitalize !important;
    font-weight: normal;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__tile {
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0.2rem;
    display: inline-flex;
    font-weight: 400;
    justify-content: center;
    min-height: 32px;
    padding: 0 5px;
    position: relative;
}

.react-calendar__tile--now {
    border: 1px solid #3d83df !important;
    border-radius: 0.2rem;
    background: transparent !important;
}

.react-calendar__tile--now.react-calendar__year-view__months__month {
    background: #3d83df !important;
    color: #fff !important;
}

.react-calendar__tile--active {
    border: 1px solid #3d83df !important;
    border-radius: 0.2rem;
    background: #3d83df !important;
    color: #fff !important;
}


.react-calendar__tile--active abbr {
    color: #fff !important;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
    color: #1b2734 !important;
    cursor: pointer;
    font-size: 95%;
    height: 32px;
    outline: none;
    text-align: center;
    width: 14.2857142857%;
}

.react-calendar__month-view__days__day:hover {
    background-color: #dcdfe3;
    border-radius: 0.2rem;
    color: #1b2734;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #98a1ab !important;
}

.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky>* {
    top: 34px;
}

.fc-media-screen {
    background-color: rgb(255, 255, 255);
    border-radius: 0.2667rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0.2667rem 0;
    border: 1px solid rgb(238, 238, 238);
    padding: 10px;
}

.fc-event-main {
    text-decoration: none;
    border-radius: 0.2667rem;
    overflow: hidden;
    padding: 2px 4px;
}

@media (max-width: 599.95px) {

    .fc-toolbar-title{
        font-size: 1rem !important;
    }
}

@media (max-width: 899.95px) {

}


.print {
    margin: 20px 50px;
}

.fc-timeGridDay-view .fc-more-link{
    min-width: 100px;
    font-size: 20px;
    font-weight: bold;
}

.fc-more-link{
    background-color: #FECE2E;
    color: #364152;
}

.fc-day-today {
    background-color: rgba(168, 218, 249, 0.3) !important;
}
